import { Injectable } from '@angular/core';
import * as linq from 'linqts';
import * as moment from 'moment';

export interface Listener {
  Name: string;
  ListenerIdentifier: string;
  MicState: string;
  IdLanguageListening: number;
  HandUp: boolean;
  Date: Date;
  IsSpeaker: boolean;

  MeetingVolumeLevel: number;
  InterpretationVolumeLevel: number;
  SystemVolumeLevel: number;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private listeners: Listener[] = [];

  constructor() { }

  public getListeners(): Listener[] {
    return this.listeners;
  }

  public getListenerById(id: number): Listener {
    return this.listeners[id];
  }

  public addOrUpdateListener(listener: Listener) {
    if (!listener || !listener.ListenerIdentifier) {
      return false;
    }
    const self = this;
    const list = this.listeners.find(p => p.ListenerIdentifier === listener.ListenerIdentifier);
    if (!list) {
      listener.Date = new Date();
      self.listeners.push(listener);
      const l = new linq.List(self.listeners.filter(p => Math.abs(moment(p.Date).diff(moment(new Date()), 'seconds')) < 15))
        .OrderBy(p => !p.IsSpeaker)
        .ThenBy(p => p.Name).ToArray();
      self.listeners = l;
      return;
    }
    const idxList = self.listeners.indexOf(list);
    // tslint:disable-next-line: forin
    for (const prop in self.listeners[idxList]) {
      self.listeners[idxList][prop] = listener[prop];
    }
    self.listeners[idxList].Date = new Date();
    self.listeners = [...self.listeners];
    return true;
  }

  public verifyListenerConnected() {
    const l = new linq.List(this.listeners.filter(p => Math.abs(moment(p.Date).diff(moment(new Date()), 'seconds')) < 15))
      .OrderBy(p => !p.IsSpeaker)
      .ThenBy(p => p.Name).ToArray();
    this.listeners = l;
  }
}
