import { Injectable } from '@angular/core';
import { MqttClient } from 'mqtt';
import { DataService, Listener } from './data.service';

export interface Message {
    fromName: string;
    subject: string;
    date: string;
    id: number;
    read: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class MqttService {
    private client: MqttClient;
    private clientOptions: any;
    private mqtt = window['mqtt'];
    private zip = window['pako'];
    private interval;
    public constructor(private dataService: DataService) {
    }

    public connectMqtt(eventCode: string) {
        const self = this;
        self.clientOptions = {
            clean: true,
            connectTimeout: 4000,
            clientId: `${eventCode}|techinical/control|${new Date().getTime()}`,
        };
        const connectUrl = 'wss://des.ulangapp.com:1884/mqtt';
        self.client = self.mqtt.connect(connectUrl, self.clientOptions);
        self.client.on('connect', () => {
            if (self.interval) {
                clearInterval(self.interval);
                self.interval = null;
            }
            self.client.subscribe(`${eventCode}|techinical/control`, (err) => {
                console.log(err || 'Subscribe ClientId Success');
            });
            self.client.subscribe(self.clientOptions.clientId, (err) => {
                console.log(err || 'Subscribe Technical/Control Success');
            });
            self.publishMessage({}, 'listener', 'RequestListenerState');
            self.interval = setInterval(() => {
                self.publishMessage({}, 'listener', 'RequestListenerState');
                setTimeout(() => {
                    self.dataService.verifyListenerConnected();
                }, 1000);
            }, 10000);
        });

        self.client.on('reconnect', (error) => {
            console.log('reconnecting:', error);
        });

        self.client.on('error', (error) => {
            console.log('Connection failed:', error);
        });

        self.client.on('message', (topic, message) => {
            const sMessage = self.zip.inflate(message, { to: 'string' });

            console.log('MqttService: Topic arrived: ' + topic);
            console.log('MqttService: Message arrived: ' + sMessage);

            if (!sMessage || sMessage === '') { return; }
            const jsonMessage = JSON.parse(sMessage);
            if (!jsonMessage) { return; }

            const type = jsonMessage.Type;
            if (type === 'ResponseListenerState') {
                const listener = jsonMessage.Packet as Listener;
                self.dataService.addOrUpdateListener(listener);
            }
        });
    }

    public publishMessage(data, topic, type, identifier?: number) {
        if (!this.client || !this.client.connected) {
            return false;
        }
        const self = this;
        const obj =
        {
            Id: self.clientOptions.clientId,
            Type: type,
            Packet: data,
            Identifier: identifier
        };
        if (identifier) {
            obj.Identifier = identifier;
        }
        const sobj = JSON.stringify(obj);
        const gzipObj = this.zip.gzip(sobj, { to: 'binary' });
        self.client.publish(topic, gzipObj, { qos: 2 });
        return true;
    }
}
